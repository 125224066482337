import React from "react";
import { motion } from "framer-motion";

import "./Hero.css";

import video from "../../Assets/Common/TAD-Cover-Video.mp4";
import whatsappIcon from "../../Assets/Common/whatsapp.webp";
import mouse from "../../Assets/Common/mouse-white.webp"
// import About from "../AboutUs/AboutUs";

const textVariants = {
  // initial: { x: -500, opacity: 0 },
  // animate: { x: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  // exit: { x: 500, opacity: 0, transition: { duration: .5 } },
  scrollButtonInitial: { y: 0, opacity: 0 },
  scrollButton: { opacity: 1, y: 10, transition: { duration: 1, repeat: Infinity } },
};

export default function Hero() {
  return (
    <div className="hero">
      {/* <div className="overlay"></div> */}
      <video src={video} autoPlay loop muted />
      <motion.div className="scroll" variants={textVariants} initial="scrollButtonInitial" animate="scrollButton">
        <img className="mouse" src={mouse} alt="" />
        <h1>Scroll to explore</h1>
      </motion.div>
      <a href="https://api.whatsapp.com/send?phone=971521034779&text=Hi%20Tad%20Video%20Production">
        <img src={whatsappIcon} alt="" className="whatsapp-icon" />
      </a>
    </div>
  );
}