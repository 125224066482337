import './App.css';
import { Fragment, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import Navbar from "./Components/Navbar/Navbar";
import Navbar1 from "./Pages/Thankyou/Navbar1"
import Footer from './Components/Footer/Footer';
import Footer1 from "./Pages/Thankyou/Footer1"
import PopupForm from './Components/PopupForm/PopupForm';
import Thankyou from './Pages/Thankyou/Thankyou';
import PageNotFound from "./Components/PageNotFound/PageNotFound"

// commit Check

function App() {

  useEffect(() => {
    import('./Components/Scripts/trackingScripts').then(({ initGoogleAnalytics, initClarity, initFacebookPixel}) => {
        initGoogleAnalytics();
        initClarity();
        initFacebookPixel();
    });
  }, []);

  const location = useLocation()
  const [formOpen, setFormOpen] = useState(true)

  const [formAppear, setFormAppear] = useState(false);

  // Function to handle closing the popup and storing flag in localStorage
  const handleClose = () => {
    setFormOpen(false);
    localStorage.setItem('formAppear', 'true'); // Set flag so popup doesn't reappear
  };

  // Check localStorage on initial load
  useEffect(() => {
    const formAppearFlag = localStorage.getItem('formAppear');
    if (formAppearFlag === 'true') {
      setFormAppear(false); // Popup should not appear if previously closed
    } else {
      setFormAppear(true); // Show popup if it's the first visit
    }
  }, []);

  // const [isPageNotFound, setIsPageNotFound ] = useState(false)

  // const handlePageNotFound = () => {
  //   setIsPageNotFound(!isPageNotFound)
  // }

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 3000); // 3000ms = 3 seconds

    // Cleanup the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fragment>
      {location.pathname !== "/thank-you" ? <Navbar /> : <Navbar1 />}
      <Routes>
        {/* <Route path="/" element={<Navigate to="/" />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/thank-you" element={<Thankyou />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {location.pathname === "/" && formOpen && formAppear && showPopup && 
        <PopupForm handleClose = {handleClose} />
      }
      {location.pathname !== "/thank-you" ? <Footer/> : <Footer1 />}
    </Fragment>
  );
}

export default App;