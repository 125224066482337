import React, { useEffect, useState } from 'react'
import "./Navbar.css"

import logo from "../../Assets/Common/TAD-production-logo-new.webp"

import menuIcon from "../../Assets/Common/menu-icon.svg"
import flag from "../../Assets/Common/UAEflag.webp"
import paper_plane from "../../Assets/Common/paper-plane.webp"
import whatsappIcon from "../../Assets/Common/whatsapp.webp";

import { useLocation } from 'react-router-dom'

const Navbar = () => {
  const location = useLocation()
  const [mobileMenu, setMobileMenu] = useState(false)
  const [sticky, setSticky] = useState(false)

  const toggle = () => {
    setMobileMenu(!mobileMenu)
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 100 ? setSticky(true) : setSticky(false)
    })
  })
  return (
    <div className="navbar">
      <nav className={`container ${sticky? "dark-nav":""}`}>
      <a href="/"><img src={logo} alt="" className="logo"></img></a>
      <ul className={mobileMenu ? "navbar-ul" : "hide-mobile-menu"}>
      <li><a href="#about-contact" className={`${location.hash === "#about-contact" ? "active" : ""}`}>About</a></li>
        <li><a href="#services" className={`${location.hash === "#services" ? "active" : ""}`}>Services</a></li>
        <li><a href="#stages" className={`${location.hash === "#our-vision" ? "active" : ""}`}>Stages</a></li>
        <li><a href="#portfolio" className={`${location.hash === "#portfolio" ? "active" : ""}`}>Portfolio</a></li>
        <li><a href="#testimonials" className={`${location.hash === "#testimonials" ? "active" : ""}`}>Testimonials</a></li>
        <a href="#about-contact"><button className="btn-mobile">
          <i><img src={paper_plane} alt="" /></i>
          <span className="text">
            CONTACT US
          </span>
          <span className="loading-animate"></span>
        </button></a>
      </ul>
      <div className="flag-number">
        <img src={flag} alt="" className="flag"/><a href="tel:+971521034779" className="phone-number no-link-style">+971 52 103 4779</a>
      </div>
      <a href="#about-contact"><button className="btn1">
        <i><img src={paper_plane} alt="" /></i>
        <span className="text">
          CONTACT US
        </span>
        <span className="loading-animate"></span>
      </button></a>
      <a href="https://api.whatsapp.com/send?phone=971521034779&text=Hi%20Tad%20Video%20Production">
        <img src={whatsappIcon} alt="" className="whatsapp-icon" />
      </a>
      <img src={menuIcon} alt="" className="menuIcon" onClick={() => toggle()}/>
    </nav>
    </div>
  )
}

export default Navbar